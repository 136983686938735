import {
    Component,
    OnInit,
    AfterViewInit,
    AfterViewChecked,
    ViewChild,
    ElementRef,
    TemplateRef,
    ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { of as observableOf, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';


import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { ProductsRepoService } from 'src/app/repositories/products-repo/products-repo.service';
import { SmdProductsRepoService } from 'src/app/repositories/smdproducts-repo/smdproducts-repo.service';
import { DipSliderComponent } from 'src/app/shared/dip-slider/dip-slider.component';
import { DipTabulatorComponent } from 'src/app/shared/dip-tabulator/dip-tabulator.component';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { SMDProductDialogComponent } from './smdproduct-dialog/smdproduct-dialog.component';
import { orderBy } from 'lodash';
import { ProductsListModel } from 'src/app/models/product.model';
import { UploadFileComponent } from "./upload-file/upload-file.component";

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit, AfterViewInit, AfterViewChecked {
    selectedTabIndex: number = 0;
    isLoading: boolean = false;
    isShowMore: boolean = false;
    isShow: boolean = true;
    _savedProducts = [];
    get savedProducts() {
        return this._savedProducts
    };

    _savedMainProducts = [];
    get savedMainProducts() {
        return this._savedMainProducts
    };

    _savedSMDProducts = [];
    get savedSMDProducts() {
        return this._savedSMDProducts
    };


    get isAdmin() {
        return this._securityRepoService.isAdmin
    };

    totalCount: number = 0;
    tooltipShowDelay = 400;
    isClearClicked: boolean = false;

    useSmartSearch = false;
    showMoreUsed = false;
    oldData = [];

    @ViewChild('UploadFileComponent', { read: UploadFileComponent }) uploadFileComponent: UploadFileComponent;
    @ViewChild('contextMenuLabelAddElem', { read: ElementRef }) contextMenuLabelAddElem: ElementRef;
    @ViewChild('contextMenuLabelAddDisabledElem', { read: ElementRef }) contextMenuLabelAddDisabledElem: ElementRef;
    @ViewChild('contextMenuLabelMoreElem', { read: ElementRef }) contextMenuLabelMoreElem: ElementRef;

    @ViewChild('productsSidenavContainer', { read: MatSidenavContainer }) productsSidenavContainer: MatSidenavContainer;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @ViewChild('tabulatorTable', { read: DipTabulatorComponent }) tabulatorTable: DipTabulatorComponent;

    @ViewChild('savedProductsTableContainer', { read: ElementRef }) savedProductsTableContainer: ElementRef;
    @ViewChild('savedProductsTable', { read: DipTabulatorComponent }) savedProductsTable: DipTabulatorComponent;
    _isSavedProductsTableCreated: boolean = false;
    _savedProductsTableWrapperHeight = 0;

    @ViewChild('savedSMDProductsTableContainer', { read: ElementRef }) savedSMDProductsTableContainer: ElementRef;
    @ViewChild('savedSMDProductsTable', { read: DipTabulatorComponent }) savedSMDProductsTable: DipTabulatorComponent;
    _isSavedSMDProductsTableCreated: boolean = false;
    _savedSMDProductsTableWrapperHeight = 0;

    @ViewChild('smdProductsTableContainer', { read: ElementRef }) smdProductsTableContainer: ElementRef;
    @ViewChild('smdProductsTable', { read: DipTabulatorComponent }) smdProductsTable: DipTabulatorComponent;
    _isSmdProductsTableCreated: boolean = false;
    _smdProductsTableWrapperHeight = 0;
    _isSmdActive = 1;

    // Products sliders
    @ViewChild('partNumberFilterElement', { read: ElementRef }) partNumberFilterElement: ElementRef;
    @ViewChild('manufacturerFilterElement', { read: ElementRef }) manufacturerFilterElement: ElementRef;
    @ViewChild('functionFilterElement', { read: ElementRef }) functionFilterElement: ElementRef;
    @ViewChild('technologyFilterElement', { read: ElementRef }) technologyFilterElement: ElementRef;
    @ViewChild('referenceFilterElement', { read: ElementRef }) referenceFilterElement: ElementRef;
    @ViewChild('ionParticleFilterElement', { read: MatSelect }) ionParticleFilterElement: MatSelect;
    @ViewChild('dataTypeFilterElement', { read: MatSelect }) dataTypeFilterElement: MatSelect;
    @ViewChild('smdDataTypeFilterElement', { read: MatSelect }) smdDataTypeFilterElement: MatSelect;

    // SMD Products sliders
    @ViewChild('vendorPartNumberFilterElement', { read: ElementRef }) vendorPartNumberFilterElement: ElementRef;
    @ViewChild('cageFilterElement', { read: ElementRef }) cageFilterElement: ElementRef;
    @ViewChild('descriptionFilterElement', { read: ElementRef }) descriptionFilterElement: ElementRef;


    // Products sliders
    @ViewChild('selSlider', { read: DipSliderComponent }) selSlider: DipSliderComponent;
    @ViewChild('tidSlider', { read: DipSliderComponent }) tidSlider: DipSliderComponent;
    @ViewChild('ddFluenceSlider', { read: DipSliderComponent }) ddFluenceSlider: DipSliderComponent;
    @ViewChild('criticalVoltageSlider', { read: DipSliderComponent }) criticalVoltageSlider: DipSliderComponent;

    // SMD Products sliders
    @ViewChild('tidHdrSlider', { read: DipSliderComponent }) tidHdrSlider: DipSliderComponent;
    @ViewChild('tidLdrSlider', { read: DipSliderComponent }) tidLdrSlider: DipSliderComponent;
    @ViewChild('selSmdSlider', { read: DipSliderComponent }) selSmdSlider: DipSliderComponent;
    @ViewChild('dseeSlider', { read: DipSliderComponent }) dseeSlider: DipSliderComponent;
    @ViewChild('seuSlider', { read: DipSliderComponent }) seuSlider: DipSliderComponent;
    @ViewChild('neutronSlider', { read: DipSliderComponent }) neutronSlider: DipSliderComponent;
    @ViewChild('doseRateLatchupSlider', { read: DipSliderComponent }) doseRateLatchupSlider: DipSliderComponent;
    @ViewChild('doseRateUpsetSlider', { read: DipSliderComponent }) doseRateUpsetSlider: DipSliderComponent;
    @ViewChild('doseRateSurvivabilitySlider', { read: DipSliderComponent }) doseRateSurvivabilitySlider: DipSliderComponent;

    // Products panels
    @ViewChild('selExpansionPanel', { read: MatExpansionPanel }) selExpansionPanel: MatExpansionPanel;
    @ViewChild('tidExpansionPanel', { read: MatExpansionPanel }) tidExpansionPanel: MatExpansionPanel;
    @ViewChild('ddFluenceExpansionPanel', { read: MatExpansionPanel }) ddFluenceExpansionPanel: MatExpansionPanel;
    @ViewChild('criticalVoltageExpansionPanel', { read: MatExpansionPanel }) criticalVoltageExpansionPanel: MatExpansionPanel;

    // SMD Products panels
    @ViewChild('tidHdrExpansionPanel', { read: MatExpansionPanel }) tidHdrExpansionPanel: MatExpansionPanel;
    @ViewChild('tidLdrExpansionPanel', { read: MatExpansionPanel }) tidLdrExpansionPanel: MatExpansionPanel;
    @ViewChild('selSmdExpansionPanel', { read: MatExpansionPanel }) selSmdExpansionPanel: MatExpansionPanel;
    @ViewChild('dseeExpansionPanel', { read: MatExpansionPanel }) dseeExpansionPanel: MatExpansionPanel;
    @ViewChild('seuExpansionPanel', { read: MatExpansionPanel }) seuExpansionPanel: MatExpansionPanel;
    @ViewChild('neutronExpansionPanel', { read: MatExpansionPanel }) neutronExpansionPanel: MatExpansionPanel;
    @ViewChild('doseRateLatchupExpansionPanel', { read: MatExpansionPanel }) doseRateLatchupExpansionPanel: MatExpansionPanel;
    @ViewChild('doseRateUpsetExpansionPanel', { read: MatExpansionPanel }) doseRateUpsetExpansionPanel: MatExpansionPanel;
    @ViewChild('doseRateSurvivabilityExpansionPanel', { read: MatExpansionPanel }) doseRateSurvivabilityExpansionPanel: MatExpansionPanel;

    @ViewChild('multipleSearchTextarea', { read: ElementRef }) multipleSearchTextarea: ElementRef;

    viewConfigs = {
        filterInputs: {
            partNumber: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            manufacturer: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            function: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            technology: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            sel: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Search for a part number or part function'
            },
            tid: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Search for a part number or part function'
            },
            ddFluence: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Search for a part number or part function'
            },
            criticalVoltage: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Search for a part number or part function'
            },
            ionParticle: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            dataType: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            reference: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible only for admin'
            },
            vendorPartNumber: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            cage: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            description: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            tidHdr: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            tidLdr: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            selSmd: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            dsee: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            seu: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            neutron: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            doseRateLatchup: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            doseRateUpset: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
            doseRateSurvivability: {
                canFilter: false,
                isDisabled: true,
                tooltipMessage: 'Accessible with subscription'
            },
        },
        freeSearchPeriod: {
            isFirstSearch: false,
            isFirstDialog: false,
            isEndDialogBox: false,
            remainingSearches: 0,
            remainingDialogs: 0
        }
    };

    particleSelectData = [];

    dataTypeSelectData = [];

    smdDataTypeSelectData = [];

    autoCompleteArray = [];

    slidersConfigs = {
        sel: {
            min: 0,
            max: 100,
            unit: '(MeV-cm<sup>2</sup>/mg)',
            step: 1,
            decimals: 0
        },
        tid: {
            min: 0,
            max: 100,
            unit: '(krad)',
            step: 1,
            decimals: 0
        },
        ddFluence: {
            min: 0,
            max: 15,
            unit: '(cm<sup>-2</sup>)',
            step: 1,
            decimals: 0,
            inputPrefix: '1.0 E'
        },
        criticalVoltage: {
            min: 0,
            max: 1000,
            unit: '(V)',
            step: 1,
            decimals: 0,
        },
        tidHdr: {
            min: 0,
            max: 1000,
            unit: '(krad)',
            step: 1,
            decimals: 0,
        },
        tidLdr: {
            min: 0,
            max: 1000,
            unit: '(krad)',
            step: 1,
            decimals: 0,
        },
        selSmd: {
            min: 0,
            max: 300,
            unit: '(MeV-cm<sup>2</sup>/mg)',
            step: 1,
            decimals: 0
        },
        dsee: {
            min: 0,
            max: 300,
            unit: '(MeV-cm<sup>2</sup>/mg)',
            step: 1,
            decimals: 0
        },
        seu: {
            min: 0,
            max: 200,
            unit: '(MeV-cm<sup>2</sup>/mg)',
            step: 1,
            decimals: 0
        },
        neutron: {
            min: 0,
            max: 15,
            unit: '(n/cm<sup>2</sup>)',
            step: 1,
            decimals: 0,
            inputPrefix: '1.0 E'
        },
        doseRateLatchup: {
            min: 0,
            max: 15,
            unit: '(rad/sec)',
            step: 1,
            decimals: 0,
            inputPrefix: '1.0 E'
        },
        doseRateUpset: {
            min: 0,
            max: 15,
            unit: '(rad/sec)',
            step: 1,
            decimals: 0,
            inputPrefix: '1.0 E'
        },
        doseRateSurvivability: {
            min: 0,
            max: 15,
            unit: '(rad/sec)',
            step: 1,
            decimals: 0,
            inputPrefix: '1.0 E'
        },
    };

    isPartNumberFilterActive: boolean = false;
    isManufacturerFilterActive: boolean = false;
    isFunctionFilterActive: boolean = false;
    isTechnologyFilterActive: boolean = false;
    isParticleFilterActive: boolean = false;
    isDataTypeFilterActive: boolean = false;
    isReferenceFilterActive: boolean = false;
    isCageFilterActive: boolean = false;
    isDescriptionFilterActive: boolean = false;
    isVendorPartNumberFilterActive: boolean = false;

    isFiltersInputContainerScrolled: boolean = false;
    filters: any = {
        part_number: '',
        manufacturer: '',
        function: '',
        technology: '',
        sel: {
            low: this.slidersConfigs.sel.min,
            hi: this.slidersConfigs.sel.max
        },
        tid: {
            low: this.slidersConfigs.tid.min,
            hi: this.slidersConfigs.tid.max
        },
        ddFluence: this.slidersConfigs.ddFluence.min,
        criticalVoltage: this.slidersConfigs.criticalVoltage.min,
        ionParticle: [],
        dataType: [],
        multiPartNumber: '',
        reference: '',
        vendorPartNumber: '',
        cage: '',
        description: '',
        tidHdr: this.slidersConfigs.tidHdr.min,
        tidLdr: this.slidersConfigs.tidLdr.min,
        dsee: this.slidersConfigs.dsee.min,
        seu: this.slidersConfigs.seu.min,
        neutron: this.slidersConfigs.neutron.min,
        doseRateLatchup: this.slidersConfigs.doseRateLatchup.min,
        doseRateUpset: this.slidersConfigs.doseRateUpset.min,
        doseRateSurvivability: this.slidersConfigs.doseRateSurvivability.min,
        selSmd: this.slidersConfigs.selSmd.min
    }

    _isSavedListIconActive: boolean = false;
    get isSavedListIconActive(): boolean {
        return this._isSavedListIconActive;
    }

    _isMultipleSearchIconActive: boolean = false;
    get isMultipleSearchIconActive(): boolean {
        return this._isMultipleSearchIconActive;
    }

    get isRightSidenavExpanded(): boolean {
        return this._isSavedListIconActive || this._isMultipleSearchIconActive
    };

    hasConditionalColumns: boolean = false;
    type: string = '';

    @ViewChild('productsFileInputElement', { read: ElementRef }) productsFileInputElementRef: ElementRef;
    isProductsFileSelected: boolean = false;

    @ViewChild('smdProductsFileInputElement', { read: ElementRef }) smdProductsFileInputElementRef: ElementRef;
    isSmdProductsFileSelected: boolean = false;

    @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any>;
    autopopMsg1 = 'Upload file and search for part numbers'
    autopopMsg2 = 'Not accessible from this tab'
    removeSorting: boolean = false;

    isFirstSearch: boolean = true;

    productsPartNumber: string = "";
    bomPartNumber: string = "";

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _securityRepoService: SecurityRepoService,
        private _productsRepo: ProductsRepoService,
        private _smdproductsRepo: SmdProductsRepoService,
        private _matDialog: MatDialog,
        private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit(): void {
        this._securityRepoService.userChanged$.subscribe(user => {
            let configs = null;
            if (user && typeof user === 'object') {
                configs = user['configs'];
            }

            this.resetTableConfig(configs);
        });
        let productsComponent = this;
        this._productsRepo.getFilters().subscribe(data => {
            this.particleSelectData = data.particle;
            this.dataTypeSelectData = data.data_type;
        });
        this._smdproductsRepo.getFilters().subscribe(data => {
            this.smdDataTypeSelectData = data.data_type;
        });
        this._activatedRoute.queryParamMap.subscribe(paramMap => {
            let tab = paramMap.get('tab');
            let tabIndex = Number(tab);
            let dvShowMore = null;
            switch (tabIndex) {
                case 0:  // database
                    this.clearFilterInputs();
                    dvShowMore = document.getElementsByClassName("dvShowMore")[0] as HTMLDivElement;
                    if (!dvShowMore && this.tabulatorTable !== undefined) {
                        this.tabulatorTable.createShowMore();
                    }
                    this.selectedTabIndex = 0;
                    break;
                case 1:  // saved
                    this.clearFilterInputs();
                    this.selectedTabIndex = 1;
                    break;
                case 2:  // needdata
                    this.clearFilterInputs();
                    this.selectedTabIndex = 2;
                    break;
                case 3: //Auto BOM scrub
                    this.clearFilterInputs();
                    this.selectedTabIndex = 3;
                    break;
                case 4: //SMD Prodcuts
                    this.clearFilterInputs();
                    this.selectedTabIndex = 4;
                    break;
                case 5:  // saved
                    this.clearFilterInputs();
                    this.selectedTabIndex = 5;
                    break;
                default:
                    this.selectedTabIndex = 0;
                    break;
            }
        });
    }

    ngAfterViewInit() {
        this.tabulatorTable.createTable(this.getTableConfig(null));
        this._savedProductsTableWrapperHeight = this.savedProductsTableContainer.nativeElement.offsetHeight;
        this.savedProductsTable.createTable(this.getSavedListTableConfig());
        this._isSavedProductsTableCreated = true;
        this.smdProductsTable.createTable(this.getTableConfig('smd'));
        this._isSmdProductsTableCreated = true;
        this._savedSMDProductsTableWrapperHeight = this.savedSMDProductsTableContainer.nativeElement.offsetHeight;
        this.savedSMDProductsTable.createTable(this.getSMDSavedListTableConfig());
        this._isSavedSMDProductsTableCreated = true;
    }

    ngAfterViewChecked() {
        this.productsSidenavContainer.updateContentMargins();

        // this fixes layout of saved products table
        let height = this.savedProductsTableContainer.nativeElement.offsetHeight;
        if (this._savedProductsTableWrapperHeight !== height) {
            this._savedProductsTableWrapperHeight = height;
            if (this._isSavedProductsTableCreated) {
                this.savedProductsTable.table.redraw(true);
            }
        }
        let smdheight = this.savedSMDProductsTableContainer.nativeElement.offsetHeight;
        if (this._savedSMDProductsTableWrapperHeight !== smdheight) {
            this._savedSMDProductsTableWrapperHeight = smdheight;
            if (this._isSavedSMDProductsTableCreated) {
                this.savedSMDProductsTable.table.redraw(true);
            }
        }
    }

    disableSortingHeaders(selector: string) {
        if (selector.length < 1) {
            return;
        }
        let element = document.querySelector(selector);
        if (element) {
            element.classList.add('removeListener')
        }
    }

    activateSortingHeaders(selector: string) {
        if (selector.length < 1) {
            return;
        }
        let element = document.querySelector(selector);
        if (element) {
            element.classList.remove('removeListener');
        }
    }

    sortData(productsComponent, field, dir, data) {
        if (!productsComponent.isClearClicked && !!field && !!dir) {
            var fst = orderBy(data.items.filter(e => typeof e[field] === 'number'), field, dir);
            var snd = orderBy(data.items.filter(e => typeof e[field] === 'string'), field, dir);
            var fstsnd = fst.concat(snd);
            data.items = fstsnd;

        } else if (productsComponent.isClearClicked) {
            productsComponent.isClearClicked = false;
        }
        return data;
    }

    processProduct(productsComponent, productsObservable, resolve, reject, params, tableType) {
        productsObservable
            .pipe(catchError(() => {
                reject();
                return observableOf([]);
            }))
            .subscribe(data => {
                resolve(data);

                let configs = null;
                if (data && typeof data === 'object' && data['configs']) {
                    configs = data['configs'];
                }


                productsComponent.updateTableConfigs(configs, tableType);
                productsComponent.displayAfterLoadMessages(params);

                let newValue = productsComponent.partNumberFilterElement.nativeElement.value.trim().toLowerCase();

                let dvShowMore = document.getElementsByClassName("dvShowMore")[0] as HTMLDivElement;
                if (dvShowMore) {
                    if (!!dvShowMore) {
                        dvShowMore.hidden = true;
                    }

                    if (
                        (
                            (data['page'] == data['last_page'] && newValue.length > 3) ||
                            (data['last_page'] == 0 && data['page'] == 1 && newValue.length > 3)
                        )
                        && configs.canFilter.part_number
                        && !this.isTab(4)
                    ) {
                        dvShowMore.hidden = false;
                    }
                }

                if (data['page'] <= 1 && productsComponent.isShowMore == true && (data['total_count'] == 0 || productsComponent.totalCount == data['total_count'])) {
                    productsComponent.addMoreResultValue();
                }

                productsComponent.totalCount = data['total_count'];

                if (dvShowMore) {
                    dvShowMore.hidden = (productsComponent.showMoreUsed) ? true : dvShowMore.hidden;
                }
            });
    }

    private getTableConfig(tableType) {
        let userConfigs = null;
        var field = null;
        var dir = null;
        var size = null;
        var page = 1;
        let isAuthenticated = this._securityRepoService.isAuthenticated;
        let user = this._securityRepoService.user;
        if (user && typeof user === 'object') {
            userConfigs = user['configs'];
        }

        let productRepo = tableType == 'smd' ? this._smdproductsRepo : this._productsRepo;
        let activeTable = tableType == 'smd' ? this.smdProductsTable : this.tabulatorTable;
        let productsComponent = this;
        let ajaxRequestFunc = (url, config, params) => {
            return new Promise(function (resolve, reject) {
                size = params.size;
                page = params.page;

                params['filters'] = productsComponent.buildFiltersQuery(productsComponent.type);
                let tempParams = params;
                let productsObservable;
                let productsObservableTemp: Observable<ProductsListModel>;
                if (isAuthenticated) {
                    let blockData = productsComponent.isFirstSearch && !productsComponent._securityRepoService.isAdmin;
                    if (!productsComponent.isClearClicked && !!params.sorters && !!params.sorters.length && activeTable.getData().length != 0 && !productsComponent.useSmartSearch && !productsComponent.removeSorting) {
                        tempParams = Object.assign({}, tempParams, { page: page, size: 20, sorters: params.sorters, useSmartSearch: false, blockData: blockData });
                        productsObservableTemp = productRepo.getProductsByParams(params);
                        productsObservableTemp
                            .subscribe(d => {
                                let data: ProductsListModel;
                                data = d;
                                productsObservable = observableOf(data);
                                productsComponent.processProduct(productsComponent, productsObservable, resolve, reject, tempParams, tableType);
                            });
                    } else if (productsComponent.isClearClicked) {
                        tempParams = Object.assign({}, tempParams, { sorters: {}, useSmartSearch: false, blockData: blockData });
                        productsComponent.processProduct(productsComponent, productRepo.getProductsByParams(tempParams), resolve, reject, tempParams, tableType);
                        productsComponent.isClearClicked = false;
                    } else {
                        params['useSmartSearch'] = productsComponent.useSmartSearch;
                        params['blockData'] = blockData;
                        let oldData = [];
                        if (!!productsComponent.oldData.length) {
                            productsComponent.oldData.forEach(element => {
                                oldData.push({
                                    id: element.id,
                                    part_number: element.part_number
                                })
                            });
                        }
                        params['oldData'] = oldData;
                        productsComponent.processProduct(productsComponent, productRepo.getProductsByParams(params), resolve, reject, params, tableType);
                        productsComponent.isClearClicked = false;
                    }

                } else {
                    productsObservableTemp = productRepo.getSampleProducts();
                    productsComponent.processProduct(productsComponent, productsObservableTemp, resolve, reject, params, tableType);
                }
            });
        }

        let openProductDialogFunction = function (e, row) {
            productsComponent.openProductDialog(row, tableType);
        };

        let openSMDProductDialogFunction = function (e, row) {
            productsComponent.openSMDProductDialog(row);
        };

        let productContextMenu = [
            {
                label: function (row) {
                    if (productsComponent.isInSavedList(row.getData())) {
                        return productsComponent.contextMenuLabelAddDisabledElem.nativeElement
                    }

                    return productsComponent.contextMenuLabelAddElem.nativeElement
                },
                action: function (e, row) {
                    productsComponent.addToSavedList(row.getData());
                },
                disabled: function (row) {
                    return productsComponent.isInSavedList(row.getData());
                }
            },
            {
                label: productsComponent.contextMenuLabelMoreElem.nativeElement,
                action: function (e, row) {
                    productsComponent.openProductDialog(row, tableType);
                }
            }
        ];

        let smdproductContextMenu = [
            {
                label: function (row) {
                    if (productsComponent.isInSavedList(row.getData())) {
                        return productsComponent.contextMenuLabelAddDisabledElem.nativeElement
                    }

                    return productsComponent.contextMenuLabelAddElem.nativeElement
                },
                action: function (e, row) {
                    productsComponent.addToSavedList(row.getData());
                },
                disabled: function (row) {
                    return productsComponent.isInSavedList(row.getData());
                }
            },
            {
                label: productsComponent.contextMenuLabelMoreElem.nativeElement,
                action: function (e, row) {
                    productsComponent.openSMDProductDialog(row);
                }
            }
        ];

        let placeholder = '';

        if (tableType == 'smd') {
            placeholder = "<div id='tabulatorPlaceholder'>Start by searching for a part number, vendor part number, description, or manufacturer's name.</div>"
        } else {
            placeholder = "<div id='tabulatorPlaceholder'>Start by searching for a part number, function, technology, or manufacturer's name.</div>"
        }

        let tableConfig = {
            height: '100%',
            layout: 'fitColumns', //fit columns to width of table (optional)
            responsiveLayout: true,
            resizableRows: !this.isTablet(),
            resizableColumns: true,
            columns: tableType == 'smd' ? this.getSmdColumnDefs(userConfigs) : this.getColumnDefs(userConfigs),
            ajaxURL: productRepo.getAjaxUrl(),
            ajaxConfig: 'GET',
            ajaxRequestFunc: ajaxRequestFunc,
            ajaxFiltering: true,
            ajaxSorting: true,
            placeholder: placeholder,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: true,
            paginationDataReceived: { 'data': 'items' },
            rowClick: tableType == 'smd' ? openSMDProductDialogFunction : openProductDialogFunction,
            rowContextMenu: tableType == 'smd' ? smdproductContextMenu : productContextMenu,
            // rowClick: openProductDialogFunction,
            // rowContextMenu: productContextMenu,
            dataFiltering: function (filters) {
                if (productsComponent.removeSorting) {
                    // productsComponent.tabulatorTable.clearSorters();
                    productsComponent.removeSorting = false;
                }
            },
            dataFiltered: function (filters, rows) {
                if (productsComponent._securityRepoService != undefined) {
                    if (!productsComponent.isFirstSearch) {
                        if (!productsComponent._securityRepoService.isAdmin) {
                            productsComponent.activateSortingHeaders('[tabulator-field="manufacturer"]')
                            productsComponent.activateSortingHeaders('[tabulator-field="function"]')
                            productsComponent.activateSortingHeaders('[tabulator-field="technology"]')
                            productsComponent.activateSortingHeaders('[tabulator-field="data_type"]')
                        }
                        productsComponent.tabulatorTable.updatePlaceholder("No data to display!");
                    } else {
                        if (!productsComponent._securityRepoService.isAdmin) {
                            productsComponent.disableSortingHeaders('[tabulator-field="manufacturer"]')
                            productsComponent.disableSortingHeaders('[tabulator-field="function"]')
                            productsComponent.disableSortingHeaders('[tabulator-field="technology"]')
                            productsComponent.disableSortingHeaders('[tabulator-field="data_type"]')
                        }
                        productsComponent.tabulatorTable.updatePlaceholder(placeholder);
                    }
                }
            }
        };

        return tableConfig;
    }

    private getColumnDefs(configs = null) {
        let normalizedConfigs = this.normalizeColumnConfigs(configs);
        let sortConfig = normalizedConfigs.canSort;
        let isAllVisible = this._securityRepoService.isAdmin;
        let columnDefs = [];

        columnDefs = [
            { title: "ID", field: "id", visible: false, headerSort: sortConfig.id, headerSortTristate: true },
            { title: "Part Number", field: "part_number", headerSort: sortConfig.part_number, headerSortTristate: true },
            {
                title: "Manufacturer",
                field: "manufacturer",
                headerSort: sortConfig.manufacturer,
                headerSortTristate: true
            },
            { title: "Function", field: "function", headerSort: sortConfig.function, headerSortTristate: true },
            { title: "Technology", field: "technology", headerSort: sortConfig.technology, headerSortTristate: true },
            { title: "Node", field: "node", visible: false, headerSort: sortConfig.node, headerSortTristate: true },
            {
                title: "Lot/date code",
                field: "ldc",
                visible: false,
                headerSort: sortConfig.ldc,
                headerSortTristate: true
            },
            {
                title: "Test Facility",
                field: "facility",
                visible: false,
                headerSort: sortConfig.facility,
                headerSortTristate: true
            },
            {
                title: "Particle",
                field: "particle",
                visible: false,
                headerSort: sortConfig.particle,
                headerSortTristate: true
            },
            { title: "Energy", field: "energy", visible: false, headerSort: sortConfig.energy, headerSortTristate: true },
            {
                title: "LET Min",
                field: "let_min",
                sorter: "number",
                visible: isAllVisible,
                headerSort: sortConfig.let_min,
                headerSortTristate: true
            },
            {
                title: "LET Max",
                field: "let_max",
                sorter: "number",
                visible: isAllVisible,
                headerSort: sortConfig.let_max,
                headerSortTristate: true
            },
            {
                title: "Survival Voltage",
                field: "survival_voltage",
                sorter: "number",
                visible: isAllVisible,
                headerSort: sortConfig.survival_voltage,
                headerSortTristate: true
            },
            {
                title: "Test Temperature",
                field: "temperature",
                visible: false,
                headerSort: sortConfig.temperature,
                headerSortTristate: true
            },
            { title: "Bias", field: "bias", visible: false, headerSort: sortConfig.bias, headerSortTristate: true },
            {
                title: "TID Min",
                field: "tid_min",
                sorter: "number",
                visible: isAllVisible,
                headerSort: sortConfig.tid_min,
                headerSortTristate: true
            },
            {
                title: "TID Max",
                field: "tid_max",
                sorter: "number",
                visible: isAllVisible,
                headerSort: sortConfig.tid_max,
                headerSortTristate: true
            },
            {
                title: "SEL LET Th Min",
                field: "sel_let_th_min",
                sorter: "number",
                visible: false,
                headerSort: sortConfig.sel_let_th_min,
                headerSortTristate: true
            },
            {
                title: "SEL LET Th Max",
                field: "sel_let_th_max",
                sorter: "number",
                visible: false,
                headerSort: sortConfig.sel_let_th_max,
                headerSortTristate: true
            },
            {
                title: "DSEE LET Th Min",
                field: "dsee_let_th_min",
                sorter: "number",
                visible: false,
                headerSort: sortConfig.dsee_let_th_min,
                headerSortTristate: true
            },
            {
                title: "DSEE LET Th Max",
                field: "dsee_let_th_max",
                sorter: "number",
                visible: false,
                headerSort: sortConfig.dsee_let_th_max,
                headerSortTristate: true
            },
            {
                title: "Dose Rate",
                field: "dose_rate",
                visible: false,
                headerSort: sortConfig.dose_rate,
                headerSortTristate: true
            },
            {
                title: "DD Fluence",
                field: "dd_fluence",
                sorter: "number",
                visible: isAllVisible,
                headerSort: sortConfig.dd_fluence,
                headerSortTristate: true,
                formatter: function (cell) {
                    let ddFluence = cell.getValue();
                    if (typeof ddFluence == 'number') {
                        return ddFluence.toExponential();
                    }

                    return ddFluence;
                }
            },
            {
                title: "Reference",
                field: "reference",
                visible: false,
                headerSort: sortConfig.reference,
                headerSortTristate: true
            },
            {
                title: "Results",
                field: "results",
                visible: false,
                headerSort: sortConfig.results,
                headerSortTristate: true
            },
            { title: "Data Type", field: "data_type", headerSort: sortConfig.data_type, headerSortTristate: true }
        ];


        return columnDefs;
    }

    private getSmdColumnDefs(configs = null) {
        let normalizedConfigs = this.normalizeSmdColumnConfigs(configs);
        let sortConfig = normalizedConfigs.canSort;
        let isAllVisible = this._securityRepoService.isAdmin;
        let columnDefs = [
            { title: "ID", field: "id", visible: false, headerSort: sortConfig.id, headerSortTristate: true },
            { title: "Part Number", field: "part_number", headerSort: sortConfig.part_number, headerSortTristate: true },
            { title: "Vendor Part Number", field: "vendor_part_number", headerSort: sortConfig.vendor_part_number, headerSortTristate: true },
            { title: "Data Type", field: "data_type", headerSort: sortConfig.data_type, headerSortTristate: true, visible: true },
            {
                title: "Manufacturer",
                field: "manufacturer",
                headerSort: sortConfig.manufacturer,
                headerSortTristate: true,
                visible: isAllVisible
            },
            { title: "CAGE", field: "cage", headerSort: sortConfig.cage, headerSortTristate: true, visible: isAllVisible },
            { title: "Description", field: "description", headerSort: sortConfig.description, headerSortTristate: true, visible: true },
            { title: "Drawing PDF Link", field: "drawing_pdf_link", headerSort: sortConfig.drawing_pdf_link, headerSortTristate: true, visible: false },
            { title: "TID HDR", field: "tid_hdr", headerSort: sortConfig.tid_hdr, headerSortTristate: true, visible: isAllVisible },
            { title: "TID LDR", field: "tid_ldr", headerSort: sortConfig.tid_ldr, headerSortTristate: true, visible: isAllVisible },
            { title: "SEL", field: "sel", headerSort: sortConfig.sel, headerSortTristate: true, visible: isAllVisible },
            { title: "DSEE", field: "dsee", headerSort: sortConfig.dsee, headerSortTristate: true, visible: isAllVisible },
            { title: "SEU", field: "seu", headerSort: sortConfig.seu, headerSortTristate: true, visible: isAllVisible },
            {
                title: "Neutron",
                field: "neutron",
                headerSort: sortConfig.neutron,
                headerSortTristate: true,
                visible: isAllVisible,
                formatter: function (cell) {
                    let neutron = cell.getValue();
                    if (typeof neutron == 'number') {
                        return neutron.toExponential();
                    }

                    return neutron;
                }
            },
            {
                title: "Dose Rate Latchup",
                field: "dose_rate_latchup",
                headerSort: sortConfig.dose_rate_latchup,
                headerSortTristate: true,
                visible: isAllVisible,
                formatter: function (cell) {
                    let dose_rate_latchup = cell.getValue();
                    if (typeof dose_rate_latchup == 'number') {
                        return dose_rate_latchup.toExponential();
                    }

                    return dose_rate_latchup;
                }
            },
            {
                title: "Dose Rate Upset",
                field: "dose_rate_upset",
                headerSort: sortConfig.dose_rate_upset,
                headerSortTristate: true,
                visible: isAllVisible,
                formatter: function (cell) {
                    let dose_rate_upset = cell.getValue();
                    if (typeof dose_rate_upset == 'number') {
                        return dose_rate_upset.toExponential();
                    }

                    return dose_rate_upset;
                }
            },
            {
                title: "Dose Rate Survivability",
                field: "dose_rate_survivability",
                headerSort: sortConfig.dose_rate_survivability,
                headerSortTristate: true,
                visible: isAllVisible,
                formatter: function (cell) {
                    let dose_rate_survivability = cell.getValue();
                    if (typeof dose_rate_survivability == 'number') {
                        return dose_rate_survivability.toExponential();
                    }

                    return dose_rate_survivability;
                }
            },
            { title: "Other Info", field: "other_info", headerSort: sortConfig.other_info, headerSortTristate: true, visible: false },
        ];

        return columnDefs;
    }

    private normalizeColumnConfigs(configs = null) {
        let hasSort = configs && typeof configs === 'object' && configs['canSort'] && typeof configs['canSort'] === 'object';
        let sortConfig;
        if (hasSort) {
            sortConfig = configs['canSort'];
        } else {
            sortConfig = {};
        }

        let normalizedConfigs = {
            canSort: {
                id: sortConfig['id'] === true ? true : false,
                part_number: sortConfig['part_number'] === true ? true : false,
                manufacturer: sortConfig['manufacturer'] === true ? true : false,
                function: sortConfig['function'] === true ? true : false,
                technology: sortConfig['technology'] === true ? true : false,
                node: sortConfig['node'] === true ? true : false,
                ldc: sortConfig['ldc'] === true ? true : false,
                facility: sortConfig['facility'] === true ? true : false,
                particle: sortConfig['particle'] === true ? true : false,
                energy: sortConfig['energy'] === true ? true : false,
                let_min: sortConfig['let_min'] === true ? true : false,
                let_max: sortConfig['let_max'] === true ? true : false,
                survival_voltage: sortConfig['survival_voltage'] === true ? true : false,
                temperature: sortConfig['temperature'] === true ? true : false,
                bias: sortConfig['bias'] === true ? true : false,
                tid_min: sortConfig['tid_min'] === true ? true : false,
                tid_max: sortConfig['tid_max'] === true ? true : false,
                sel_let_th_min: sortConfig['sel_let_th_min'] === true ? true : false,
                sel_let_th_max: sortConfig['sel_let_th_max'] === true ? true : false,
                dsee_let_th_min: sortConfig['dsee_let_th_min'] === true ? true : false,
                dsee_let_th_max: sortConfig['dsee_let_th_max'] === true ? true : false,
                dose_rate: sortConfig['dose_rate'] === true ? true : false,
                dd_fluence: sortConfig['dd_fluence'] === true ? true : false,
                reference: sortConfig['reference'] === true ? true : false,
                results: sortConfig['results'] === true ? true : false,
                data_type: sortConfig['data_type'] === true ? true : false
            }
        };

        return normalizedConfigs;
    }

    private normalizeSmdColumnConfigs(configs = null) {
        let hasSort = configs && typeof configs === 'object' && configs['canSort'] && typeof configs['canSort'] === 'object';
        let sortConfig;
        if (hasSort) {
            sortConfig = configs['canSort'];
        } else {
            sortConfig = {};
        }

        let normalizedConfigs = {
            canSort: {
                id: sortConfig['id'] === true ? true : false,
                part_number: sortConfig['part_number'] === true ? true : false,
                vendor_part_number: sortConfig['vendor_part_number'] === true ? true : false,
                manufacturer: sortConfig['manufacturer'] === true ? true : false,
                data_type: sortConfig['data_type'] === true ? true : false,
                cage: sortConfig['cage'] === true ? true : false,
                description: sortConfig['description'] === true ? true : false,
                drawing_pdf_link: sortConfig['drawing_pdf_link'] === true ? true : false,
                tid_hdr: sortConfig['tid_hdr'] === true ? true : false,
                tid_ldr: sortConfig['tid_ldr'] === true ? true : false,
                dsee: sortConfig['dsee'] === true ? true : false,
                sel: sortConfig['sel'] === true ? true : false,
                seu: sortConfig['seu'] === true ? true : false,
                neutron: sortConfig['neutron'] === true ? true : false,
                dose_rate_latchup: sortConfig['dose_rate_latchup'] === true ? true : false,
                dose_rate_upset: sortConfig['dose_rate_upset'] === true ? true : false,
                dose_rate_survivability: sortConfig['dose_rate_survivability'] === true ? true : false,
                other_info: sortConfig['other_info'] === true ? true : false,
            }
        };

        return normalizedConfigs;
    }

    private getConditionalColumnsNames() {
        return [
            'let_min',
            'let_max',
            'survival_voltage',
            'tid_min',
            'tid_max',
            'dd_fluence'
        ];
    }

    private getConditionalSMDColumnsNames() {
        return [
            'tid_hdr',
            'tid_ldr',
            'dsee',
            'sel',
            'seu',
            'neutron',
            'dose_rate_latchup',
            'dose_rate_upset',
            'dose_rate_survivability',
        ];
    }

    resetTableConfig(configs) {
        let newColumns = this.getColumnDefs(configs);
        this.tabulatorTable.table.setColumns(newColumns);
    }

    updateTableConfigs(configs, tableType) {
        let isValidConfig = configs && typeof configs === 'object';

        if (isValidConfig && configs['has_conditional_columns'] === true) {
            this.redrawConditionalColumns(true, tableType);
        } else {
            this.redrawConditionalColumns(false, tableType);
        }

        let canFilter = {};
        if (isValidConfig && configs['canFilter']) {
            canFilter = configs['canFilter'];
        }
        if (!tableType) {
            this.viewConfigs.filterInputs.partNumber.canFilter = canFilter['part_number'] === true ? true : false;
            this.viewConfigs.filterInputs.manufacturer.canFilter = canFilter['manufacturer'] === true ? true : false;
            this.viewConfigs.filterInputs.function.canFilter = canFilter['function'] === true ? true : false;
            this.viewConfigs.filterInputs.technology.canFilter = canFilter['technology'] === true ? true : false;
            this.viewConfigs.filterInputs.sel.canFilter = canFilter['sel_let_th_min'] === true ? true : false;
            this.viewConfigs.filterInputs.tid.canFilter = canFilter['tid_min'] === true ? true : false;
            this.viewConfigs.filterInputs.ddFluence.canFilter = canFilter['dd_fluence'] === true ? true : false;
            this.viewConfigs.filterInputs.criticalVoltage.canFilter = canFilter['survival_voltage'] === true ? true : false;
            this.viewConfigs.filterInputs.ionParticle.canFilter = canFilter['particle'] === true ? true : false;
            this.viewConfigs.filterInputs.dataType.canFilter = canFilter['data_type'] === true ? true : false;
            this.viewConfigs.filterInputs.reference.canFilter = canFilter['reference'] === true ? true : false;

            let wasDisabledPartNumber = this.viewConfigs.filterInputs.partNumber.isDisabled;
            this.viewConfigs.filterInputs.partNumber.isDisabled = !this.viewConfigs.filterInputs.partNumber.canFilter;
            if (wasDisabledPartNumber === true && this.viewConfigs.filterInputs.partNumber.isDisabled === true) {
                this.clearFilterInputs();
            }

            this.viewConfigs.filterInputs.manufacturer.isDisabled = !this.viewConfigs.filterInputs.manufacturer.canFilter;
            this.viewConfigs.filterInputs.function.isDisabled = !this.viewConfigs.filterInputs.function.canFilter;
            this.viewConfigs.filterInputs.technology.isDisabled = !this.viewConfigs.filterInputs.technology.canFilter;
            this.viewConfigs.filterInputs.ionParticle.isDisabled = !this.viewConfigs.filterInputs.ionParticle.canFilter;
            this.viewConfigs.filterInputs.dataType.isDisabled = !this.viewConfigs.filterInputs.dataType.canFilter;
            this.viewConfigs.filterInputs.reference.isDisabled = !this.viewConfigs.filterInputs.reference.canFilter;

            this.viewConfigs.filterInputs.sel.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.sel.canFilter);
            if (this.viewConfigs.filterInputs.sel.isDisabled) {
                this.selExpansionPanel.close();
                this.selSlider.reset();
            }

            this.viewConfigs.filterInputs.tid.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.tid.canFilter);
            if (this.viewConfigs.filterInputs.tid.isDisabled) {
                this.tidExpansionPanel.close();
                this.tidSlider.reset();
            }

            this.viewConfigs.filterInputs.ddFluence.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.ddFluence.canFilter);
            if (this.viewConfigs.filterInputs.ddFluence.isDisabled) {
                this.ddFluenceExpansionPanel.close();
                this.ddFluenceSlider.reset();
            }

            this.viewConfigs.filterInputs.criticalVoltage.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.criticalVoltage.canFilter);
            if (this.viewConfigs.filterInputs.criticalVoltage.isDisabled) {
                this.criticalVoltageExpansionPanel.close();
                this.criticalVoltageSlider.reset();
            }

            this.viewConfigs.filterInputs.ionParticle.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.ionParticle.canFilter);
            if (this.viewConfigs.filterInputs.ionParticle.isDisabled) {
                this.ionParticleFilterElement.options.forEach((data: MatOption) => data.deselect());
                this.ionParticleFilterElement.value = null;
            }

            this.viewConfigs.filterInputs.dataType.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.dataType.canFilter);
            if (this.viewConfigs.filterInputs.dataType.isDisabled) {
                this.dataTypeFilterElement.options.forEach((data: MatOption) => data.deselect());
                this.dataTypeFilterElement.value = null;
            }

            let msg_1 = 'Search for a part number or part function';
            let msg_2 = 'Accessible with subscription';
            this.viewConfigs.filterInputs.sel.tooltipMessage = this.viewConfigs.filterInputs.sel.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.tid.tooltipMessage = this.viewConfigs.filterInputs.tid.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.ddFluence.tooltipMessage = this.viewConfigs.filterInputs.ddFluence.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.criticalVoltage.tooltipMessage = this.viewConfigs.filterInputs.criticalVoltage.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.ionParticle.tooltipMessage = this.viewConfigs.filterInputs.ionParticle.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.dataType.tooltipMessage = this.viewConfigs.filterInputs.dataType.canFilter ? msg_1 : msg_2;

        } else {

            this.viewConfigs.filterInputs.vendorPartNumber.canFilter = canFilter['vendor_part_number'] === true ? true : false;
            this.viewConfigs.filterInputs.dataType.canFilter = canFilter['data_type'] === true ? true : false;
            this.viewConfigs.filterInputs.cage.canFilter = canFilter['cage'] === true ? true : false;
            this.viewConfigs.filterInputs.description.canFilter = canFilter['description'] === true ? true : false;
            this.viewConfigs.filterInputs.tidHdr.canFilter = canFilter['tid_hdr'] === true ? true : false;
            this.viewConfigs.filterInputs.tidLdr.canFilter = canFilter['tid_ldr'] === true ? true : false;
            this.viewConfigs.filterInputs.selSmd.canFilter = canFilter['sel'] === true ? true : false;
            this.viewConfigs.filterInputs.dsee.canFilter = canFilter['dsee'] === true ? true : false;
            this.viewConfigs.filterInputs.seu.canFilter = canFilter['seu'] === true ? true : false;
            this.viewConfigs.filterInputs.neutron.canFilter = canFilter['neutron'] === true ? true : false;
            this.viewConfigs.filterInputs.doseRateLatchup.canFilter = canFilter['dose_rate_latchup'] === true ? true : false;
            this.viewConfigs.filterInputs.doseRateUpset.canFilter = canFilter['dose_rate_upset'] === true ? true : false;
            this.viewConfigs.filterInputs.doseRateSurvivability.canFilter = canFilter['dose_rate_survivability'] === true ? true : false;


            this.viewConfigs.filterInputs.vendorPartNumber.isDisabled = !this.viewConfigs.filterInputs.vendorPartNumber.canFilter;
            this.viewConfigs.filterInputs.cage.isDisabled = !this.viewConfigs.filterInputs.cage.canFilter;
            this.viewConfigs.filterInputs.description.isDisabled = !this.viewConfigs.filterInputs.description.canFilter;
            this.viewConfigs.filterInputs.dataType.isDisabled = !this.viewConfigs.filterInputs.dataType.canFilter;

            this.viewConfigs.filterInputs.tidHdr.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.tidHdr.canFilter);
            if (this.viewConfigs.filterInputs.tidHdr.isDisabled) {
                this.tidHdrExpansionPanel.close();
                this.tidHdrSlider.reset();
            }

            this.viewConfigs.filterInputs.tidLdr.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.tidLdr.canFilter);
            if (this.viewConfigs.filterInputs.tidLdr.isDisabled) {
                this.tidLdrExpansionPanel.close();
                this.tidLdrSlider.reset();
            }

            this.viewConfigs.filterInputs.selSmd.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.selSmd.canFilter);
            if (this.viewConfigs.filterInputs.selSmd.isDisabled) {
                this.selSmdExpansionPanel.close();
                this.selSmdSlider.reset();
            }

            this.viewConfigs.filterInputs.dsee.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.dsee.canFilter);
            if (this.viewConfigs.filterInputs.dsee.isDisabled) {
                this.dseeExpansionPanel.close();
                this.dseeSlider.reset();
            }

            this.viewConfigs.filterInputs.seu.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.seu.canFilter);
            if (this.viewConfigs.filterInputs.seu.isDisabled) {
                this.seuExpansionPanel.close();
                this.seuSlider.reset();
            }

            this.viewConfigs.filterInputs.neutron.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.neutron.canFilter);
            if (this.viewConfigs.filterInputs.neutron.isDisabled) {
                this.neutronExpansionPanel.close();
                this.neutronSlider.reset();
            }

            this.viewConfigs.filterInputs.doseRateLatchup.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.doseRateLatchup.canFilter);
            if (this.viewConfigs.filterInputs.doseRateLatchup.isDisabled) {
                this.doseRateLatchupExpansionPanel.close();
                this.doseRateLatchupSlider.reset();
            }

            this.viewConfigs.filterInputs.doseRateUpset.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.doseRateUpset.canFilter);
            if (this.viewConfigs.filterInputs.doseRateUpset.isDisabled) {
                this.doseRateUpsetExpansionPanel.close();
                this.doseRateUpsetSlider.reset();
            }

            this.viewConfigs.filterInputs.doseRateSurvivability.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.doseRateSurvivability.canFilter);
            if (this.viewConfigs.filterInputs.doseRateSurvivability.isDisabled) {
                this.doseRateSurvivabilityExpansionPanel.close();
                this.doseRateSurvivabilitySlider.reset();
            }

            this.viewConfigs.filterInputs.dataType.isDisabled = !(this.hasConditionalColumns && this.viewConfigs.filterInputs.dataType.canFilter);
            if (this.viewConfigs.filterInputs.dataType.isDisabled) {
                this.smdDataTypeFilterElement.options.forEach((data: MatOption) => data.deselect());
                this.smdDataTypeFilterElement.value = null;
            }

            let msg_1 = 'Search for a part number or part function';
            let msg_2 = 'Accessible with subscription';
            this.viewConfigs.filterInputs.tidHdr.tooltipMessage = this.viewConfigs.filterInputs.tidHdr.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.tidLdr.tooltipMessage = this.viewConfigs.filterInputs.tidLdr.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.selSmd.tooltipMessage = this.viewConfigs.filterInputs.selSmd.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.dsee.tooltipMessage = this.viewConfigs.filterInputs.dsee.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.seu.tooltipMessage = this.viewConfigs.filterInputs.seu.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.neutron.tooltipMessage = this.viewConfigs.filterInputs.neutron.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.doseRateLatchup.tooltipMessage = this.viewConfigs.filterInputs.doseRateLatchup.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.doseRateUpset.tooltipMessage = this.viewConfigs.filterInputs.doseRateUpset.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.doseRateSurvivability.tooltipMessage = this.viewConfigs.filterInputs.doseRateSurvivability.canFilter ? msg_1 : msg_2;
            this.viewConfigs.filterInputs.dataType.tooltipMessage = this.viewConfigs.filterInputs.dataType.canFilter ? msg_1 : msg_2;
        }

        if (isValidConfig && configs['freeSearchPeriod'] && typeof configs['freeSearchPeriod'] === 'object') {
            this.viewConfigs.freeSearchPeriod.isFirstSearch = configs['freeSearchPeriod']['isFirstSearch'] === true ? true : false;
            this.viewConfigs.freeSearchPeriod.isFirstDialog = configs['freeSearchPeriod']['isFirstDialog'] === true ? true : false;
            this.viewConfigs.freeSearchPeriod.isEndDialogBox = configs['freeSearchPeriod']['isEndDialogBox'] === true ? true : false;

            let remainingSearches = Number(configs['freeSearchPeriod']['remainingSearches']);
            this.viewConfigs.freeSearchPeriod.remainingSearches = isNaN(remainingSearches) ? 0 : remainingSearches;

            let remainingDialogs = Number(configs['freeSearchPeriod']['remainingDialogs']);
            this.viewConfigs.freeSearchPeriod.remainingDialogs = isNaN(remainingDialogs) ? 0 : remainingDialogs;
        }
    }

    displayAfterLoadMessages(params) {
        if (!params || typeof params !== 'object') {
            return;
        }

        if (!Array.isArray(params['filters']) || params['filters'].length < 1) {
            return;
        }
        if ((this.viewConfigs.freeSearchPeriod.isFirstSearch || this.viewConfigs.freeSearchPeriod.remainingSearches == 3) && !this.isTab(4)) {
            let msg = 'You have ' + this.viewConfigs.freeSearchPeriod.remainingSearches + ' searches remaining!';
            this._snackBar.open(msg, 'ok', { duration: 7000 })
        }
    }

    redrawConditionalColumns(hasConditionalColumns: boolean, tableType = '') {
        let colNames = tableType == 'smd' ? this.getConditionalSMDColumnsNames() : this.getConditionalColumnsNames();
        let activeTable = tableType == 'smd' ? this.smdProductsTable : this.tabulatorTable;
        if (hasConditionalColumns === true) {
            this.hasConditionalColumns = true;
            for (let name of colNames) {
                activeTable.table.showColumn(name);
            }
        } else {
            this.hasConditionalColumns = false;
            for (let name of colNames) {
                activeTable.table.hideColumn(name);
            }
        }

        activeTable.table.redraw(true);
    }

    getSavedListTableConfig() {
        let productsComponent = this;
        let openProductDialogFunction = function (e, row) {
            productsComponent.openProductDialog(row);
        };

        let tableConfig = {
            height: '100%',
            layout: 'fitColumns', //fit columns to width of table (optional)
            responsiveLayout: true,
            resizableRows: true,
            data: this._savedMainProducts,
            columns: this.getColumnDefs(null),
            placeholder: 'No data to display!',
            pagination: 'local',
            paginationSize: 20,
            paginationSizeSelector: true,
            rowClick: openProductDialogFunction,
            rowContextMenu: [
                {
                    label: function (row) {
                        if (productsComponent.isInSavedList(row.getData())) {
                            return productsComponent.contextMenuLabelAddDisabledElem.nativeElement
                        }

                        return productsComponent.contextMenuLabelAddElem.nativeElement
                    },
                    action: function (e, row) {
                        productsComponent.addToSavedList(row.getData());
                    },
                    disabled: function (row) {
                        return productsComponent.isInSavedList(row.getData());
                    }
                },
                {
                    label: productsComponent.contextMenuLabelMoreElem.nativeElement,
                    action: function (e, row) {
                        productsComponent.openProductDialog(row);
                    }
                }
            ]
        };

        return tableConfig;
    }

    getSMDSavedListTableConfig() {
        let productsComponent = this;
        let openProductDialogFunction = function (e, row) {
            productsComponent.openSMDProductDialog(row);
        };

        let tableConfig = {
            height: '100%',
            layout: 'fitColumns', //fit columns to width of table (optional)
            responsiveLayout: true,
            resizableRows: true,
            data: this._savedSMDProducts,
            columns: this.getSmdColumnDefs(null),
            placeholder: 'No data to display!',
            pagination: 'local',
            paginationSize: 20,
            paginationSizeSelector: true,
            rowClick: openProductDialogFunction,
            rowContextMenu: [
                {
                    label: function (row) {
                        if (productsComponent.isInSavedList(row.getData())) {
                            return productsComponent.contextMenuLabelAddDisabledElem.nativeElement
                        }

                        return productsComponent.contextMenuLabelAddElem.nativeElement
                    },
                    action: function (e, row) {
                        productsComponent.addToSavedList(row.getData());
                    },
                    disabled: function (row) {
                        return productsComponent.isInSavedList(row.getData());
                    }
                },
                {
                    label: productsComponent.contextMenuLabelMoreElem.nativeElement,
                    action: function (e, row) {
                        productsComponent.openSMDProductDialog(row);
                    }
                }
            ]
        };

        return tableConfig;
    }

    truncateCellValue(val) {
        if (typeof val === 'string' && val.length > 12) {
            return val.slice(0, 12) + ' ...';
        }

        return val;
    }

    addMoreResultValue() {

        let productsComponent = this;
        let newValue = productsComponent.partNumberFilterElement.nativeElement.value.trim().toLowerCase();
        this.isShowMore = true;

        if (newValue.length >= 3) {
            this.isShow = true;
            productsComponent.partNumberFilterElement.nativeElement.value = newValue;
            productsComponent.filters.part_number = newValue;

            if (!this.showMoreUsed) {
                this.removeSorting = true;

                productsComponent.setActiveStates();
                let tabFilters = this.buildFiltersQuery(this.type);
                this.useSmartSearch = true;
                if (this.isTab(4)) {
                    this.smdProductsTable.setFilter(tabFilters);
                } else {
                    this.tabulatorTable.setFilter(tabFilters);
                }
                this.showMoreUsed = true;
            }
        }
    }

    showMoreResult(event: any) {
        this.addMoreResultValue();
    }

    applyFilter(event: any, type = '') {
        if (!this.isTab(3)) {
            let filterChanged: boolean = false;
            let newValue = this.partNumberFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.part_number !== newValue) {
                this.filters.part_number = newValue;
                filterChanged = true;
                this.useSmartSearch = false;
                this.isShowMore = false;
                this.showMoreUsed = false;
            }

            newValue = this.manufacturerFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.manufacturer !== newValue) {
                this.filters.manufacturer = newValue;
                filterChanged = true;
            }

            newValue = this.functionFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.function !== newValue) {
                this.filters.function = newValue;
                filterChanged = true;
            }

            newValue = this.technologyFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.technology !== newValue) {
                this.filters.technology = newValue;
                filterChanged = true;
            }

            newValue = this.selSlider.getLowValue();
            if (this.filters.sel.low !== newValue) {
                this.filters.sel.low = newValue;
                filterChanged = true;
            }

            newValue = this.selSlider.getHiValue();
            if (this.filters.sel.hi !== newValue) {
                this.filters.sel.hi = newValue;
                filterChanged = true;
            }

            newValue = this.tidSlider.getLowValue();
            if (this.filters.tid.low !== newValue) {
                this.filters.tid.low = newValue;
                filterChanged = true;
            }

            newValue = this.tidSlider.getHiValue();
            if (this.filters.tid.hi !== newValue) {
                this.filters.tid.hi = newValue;
                filterChanged = true;
            }

            newValue = this.ddFluenceSlider.getLowValue();
            if (this.filters.ddFluence !== newValue) {
                this.filters.ddFluence = newValue;
                filterChanged = true;
            }

            newValue = this.criticalVoltageSlider.getLowValue();
            if (this.filters.criticalVoltage !== newValue) {
                this.filters.criticalVoltage = newValue;
                filterChanged = true;
            }

            if (type === 'selectIon') {
                newValue = event.value;
                if (this.filters.ionParticle !== newValue) {
                    this.filters.ionParticle = newValue;
                    filterChanged = true;
                }
            }

            if (type === 'selectDataType') {
                newValue = event.value;
                if (this.filters.dataType !== newValue) {
                    this.filters.dataType = newValue;
                    filterChanged = true;
                }
            }

            if (type === 'referenceInput') {
                newValue = event;
                if (this.filters.reference !== newValue) {
                    this.filters.reference = newValue;
                    filterChanged = true;
                }
            }

            newValue = this.vendorPartNumberFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.vendorPartNumber !== newValue) {
                this.filters.vendorPartNumber = newValue;
                filterChanged = true;
            }

            newValue = this.cageFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.cage !== newValue) {
                this.filters.cage = newValue;
                filterChanged = true;
            }

            newValue = this.descriptionFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.description !== newValue) {
                this.filters.description = newValue;
                filterChanged = true;
            }

            newValue = this.tidHdrSlider.getLowValue();
            if (this.filters.tidHdr !== newValue) {
                this.filters.tidHdr = newValue;
                filterChanged = true;
            }

            newValue = this.tidLdrSlider.getLowValue();
            if (this.filters.tidLdr !== newValue) {
                this.filters.tidLdr = newValue;
                filterChanged = true;
            }

            newValue = this.selSmdSlider.getLowValue();
            if (this.filters.selSmd !== newValue) {
                this.filters.selSmd = newValue;
                filterChanged = true;
            }

            newValue = this.dseeSlider.getLowValue();
            if (this.filters.dsee !== newValue) {
                this.filters.dsee = newValue;
                filterChanged = true;
            }

            newValue = this.seuSlider.getLowValue();
            if (this.filters.seu !== newValue) {
                this.filters.seu = newValue;
                filterChanged = true;
            }

            newValue = this.neutronSlider.getLowValue();
            if (this.filters.neutron !== newValue) {
                this.filters.neutron = newValue;
                filterChanged = true;
            }

            newValue = this.doseRateLatchupSlider.getLowValue();
            if (this.filters.doseRateLatchup !== newValue) {
                this.filters.doseRateLatchup = newValue;
                filterChanged = true;
            }

            newValue = this.doseRateLatchupSlider.getLowValue();
            if (this.filters.doseRateLatchup !== newValue) {
                this.filters.doseRateLatchup = newValue;
                filterChanged = true;
            }

            newValue = this.doseRateUpsetSlider.getLowValue();
            if (this.filters.doseRateUpset !== newValue) {
                this.filters.doseRateUpset = newValue;
                filterChanged = true;
            }

            newValue = this.doseRateSurvivabilitySlider.getLowValue();
            if (this.filters.doseRateSurvivability !== newValue) {
                this.filters.doseRateSurvivability = newValue;
                filterChanged = true;
            }


            if (filterChanged) {
                this.removeSorting = true;
                this.setActiveStates();
                let allInputsEmpty = this.filters.part_number.length == 0
                    && this.filters.manufacturer.length == 0
                    && this.filters.description.length == 0
                    && this.filters.vendorPartNumber.length == 0
                    && this.filters.function.length == 0
                    && this.filters.technology.length == 0
                this.isFirstSearch = (allInputsEmpty && !this._securityRepoService.isAdmin) ? true : false;
                this.oldData = [];
                let tabFilters = this.buildFiltersQuery(this.type);
                if (this.isTab(4)) {
                    this.smdProductsTable.setFilter(tabFilters);
                } else {
                    this.tabulatorTable.setFilter(tabFilters);
                }
            }
        } else {
            let filters = [];

            let filterChanged: boolean = false;
            let newValue = this.partNumberFilterElement.nativeElement.value.trim().toLowerCase();
            if (this.filters.part_number !== newValue) {
                this.filters.part_number = newValue;
                filterChanged = true;
            }

            newValue = this.selSlider.getLowValue();
            if (this.filters.sel.low !== newValue) {
                this.filters.sel.low = newValue;
                filterChanged = true;
            }

            newValue = this.selSlider.getHiValue();
            if (this.filters.sel.hi !== newValue) {
                this.filters.sel.hi = newValue;
                filterChanged = true;
            }

            newValue = this.tidSlider.getLowValue();
            if (this.filters.tid.low !== newValue) {
                this.filters.tid.low = newValue;
                filterChanged = true;
            }

            newValue = this.tidSlider.getHiValue();
            if (this.filters.tid.hi !== newValue) {
                this.filters.tid.hi = newValue;
                filterChanged = true;
            }

            newValue = this.ddFluenceSlider.getLowValue();
            if (this.filters.ddFluence !== newValue) {
                this.filters.ddFluence = newValue;
                filterChanged = true;
            }

            newValue = this.criticalVoltageSlider.getLowValue();
            if (this.filters.criticalVoltage !== newValue) {
                this.filters.criticalVoltage = newValue;
                filterChanged = true;
            }

            if (filterChanged && this.uploadFileComponent.isTableAutopop) {
                this.setActiveStates();

                let tabFilters = this.buildFiltersQuery();
                this.uploadFileComponent.onFiltersChanged(tabFilters);
            }
        }
    }

    clearFilters(event: Event) {

        if (this.isTab(3)) {
            this.uploadFileComponent.clearFilters();
            this.clearFilterInputs();
        }

        this.useSmartSearch = false;
        this.showMoreUsed = false;
        this.isClearClicked = true;
        this.isFirstSearch = true;
        this.oldData = [];

        let isFilterChanged: boolean = false;
        let newValue = '';

        this.clearAutoComplete();

        if (this.filters.part_number !== newValue) {
            this.filters.part_number = newValue;
            isFilterChanged = true;
        }
        this.partNumberFilterElement.nativeElement.value = '';

        if (this.filters.manufacturer !== newValue) {
            this.filters.manufacturer = newValue;
            isFilterChanged = true;
        }
        this.manufacturerFilterElement.nativeElement.value = '';

        if (this.filters.function !== newValue) {
            this.filters.function = newValue;
            isFilterChanged = true;
        }
        this.functionFilterElement.nativeElement.value = '';

        if (this.filters.technology !== newValue) {
            this.filters.technology = newValue;
            isFilterChanged = true;
        }
        this.technologyFilterElement.nativeElement.value = '';

        this.selExpansionPanel.close();
        if (this.selSlider.isActive) {
            this.selSlider.reset();
            isFilterChanged = true;
        }

        this.tidExpansionPanel.close();
        if (this.tidSlider.isActive) {
            this.tidSlider.reset();
            isFilterChanged = true;
        }

        this.ddFluenceExpansionPanel.close();
        if (this.ddFluenceSlider.isActive) {
            this.ddFluenceSlider.reset();
            isFilterChanged = true;
        }

        this.criticalVoltageExpansionPanel.close();
        if (this.criticalVoltageSlider.isActive) {
            this.criticalVoltageSlider.reset();
            isFilterChanged = true;
        }

        if (this.filters.reference !== newValue) {
            this.filters.reference = newValue;
            isFilterChanged = true;
        }

        if (this.isAdmin && this.referenceFilterElement) {
            this.referenceFilterElement.nativeElement.value = '';
        }

        if (this.filters.ionParticle !== newValue) {
            this.filters.ionParticle = newValue;
            isFilterChanged = true;
        }
        this.ionParticleFilterElement.options.forEach((data: MatOption) => data.deselect());
        this.ionParticleFilterElement.value = null;

        if (this.filters.dataType !== newValue) {
            this.filters.dataType = newValue;
            isFilterChanged = true;
        }

        this.dataTypeFilterElement.options.forEach((data: MatOption) => data.deselect());
        this.dataTypeFilterElement.value = null;
        this.smdDataTypeFilterElement.options.forEach((data: MatOption) => data.deselect());
        this.smdDataTypeFilterElement.value = null;

        if (this.filters.vendorPartNumber !== newValue) {
            this.filters.vendorPartNumber = newValue;
            isFilterChanged = true;
        }

        if (this.vendorPartNumberFilterElement) {
            this.vendorPartNumberFilterElement.nativeElement.value = '';
        }

        if (this.filters.cage !== newValue) {
            this.filters.cage = newValue;
            isFilterChanged = true;
        }

        if (this.cageFilterElement) {
            this.cageFilterElement.nativeElement.value = '';
        }

        if (this.filters.description !== newValue) {
            this.filters.description = newValue;
            isFilterChanged = true;
        }

        if (this.descriptionFilterElement) {
            this.descriptionFilterElement.nativeElement.value = '';
        }

        this.tidHdrExpansionPanel.close();
        if (this.tidHdrSlider.isActive) {
            this.tidHdrSlider.reset();
            isFilterChanged = true;
        }

        this.tidLdrExpansionPanel.close();
        if (this.tidLdrSlider.isActive) {
            this.tidLdrSlider.reset();
            isFilterChanged = true;
        }

        this.selSmdExpansionPanel.close();
        if (this.selSmdSlider.isActive) {
            this.selSmdSlider.reset();
            isFilterChanged = true;
        }

        this.dseeExpansionPanel.close();
        if (this.dseeSlider.isActive) {
            this.dseeSlider.reset();
            isFilterChanged = true;
        }

        this.seuExpansionPanel.close();
        if (this.seuSlider.isActive) {
            this.seuSlider.reset();
            isFilterChanged = true;
        }

        this.neutronExpansionPanel.close();
        if (this.neutronSlider.isActive) {
            this.neutronSlider.reset();
            isFilterChanged = true;
        }

        this.doseRateLatchupExpansionPanel.close();
        if (this.doseRateLatchupSlider.isActive) {
            this.doseRateLatchupSlider.reset();
            isFilterChanged = true;
        }
        this.doseRateUpsetExpansionPanel.close();
        if (this.doseRateUpsetSlider.isActive) {
            this.doseRateUpsetSlider.reset();
            isFilterChanged = true;
        }
        this.doseRateSurvivabilityExpansionPanel.close();
        if (this.doseRateSurvivabilitySlider.isActive) {
            this.doseRateSurvivabilitySlider.reset();
            isFilterChanged = true;
        }

        if (this.multipleSearchTextarea && this.multipleSearchTextarea.nativeElement) {
            this.multipleSearchTextarea.nativeElement.value = '';
        }

        if (this.filters.multiPartNumber !== '') {
            this.filters.multiPartNumber = '';
            isFilterChanged = true;
        }

        if (isFilterChanged) {
            this.setActiveStates();
            this.smdProductsTable.setFilter([]);
            this.tabulatorTable.setFilter([]);
        }


    }

    private setActiveStates() {
        if (this.filters.part_number == '') {
            this.isPartNumberFilterActive = false;
        } else {
            this.isPartNumberFilterActive = true;
        }

        if (this.filters.manufacturer == '') {
            this.isManufacturerFilterActive = false;
        } else {
            this.isManufacturerFilterActive = true;
        }

        if (this.filters.function == '') {
            this.isFunctionFilterActive = false;
        } else {
            this.isFunctionFilterActive = true;
        }

        if (this.filters.technology == '') {
            this.isTechnologyFilterActive = false;
        } else {
            this.isTechnologyFilterActive = true;
        }

        if (this.filters.ionParticle == '') {
            this.isParticleFilterActive = false;
        } else {
            this.isParticleFilterActive = true;
        }

        if (this.filters.dataType == '') {
            this.isDataTypeFilterActive = false;
        } else {
            this.isDataTypeFilterActive = true;
        }

        if (this.filters.reference == '') {
            this.isReferenceFilterActive = false;
        } else {
            this.isReferenceFilterActive = true;
        }

        if (this.filters.vendorPartNumber == '') {
            this.isVendorPartNumberFilterActive = false;
        } else {
            this.isVendorPartNumberFilterActive = true;
        }
        if (this.filters.cage == '') {
            this.isCageFilterActive = false;
        } else {
            this.isCageFilterActive = true;
        }
        if (this.filters.description == '') {
            this.isDescriptionFilterActive = false;
        } else {
            this.isDescriptionFilterActive = true;
        }
    }

    private buildFiltersQuery(type = '') {
        let filtersQuery = [];
        if (typeof this.filters.part_number === 'string' && this.filters.part_number.length > 0) {
            let filter;
            if (type) {
                filter = {
                    'field': 'part_number',
                    'type': type,
                    'value': this.filters.part_number
                };
            } else {
                filter = {
                    'field': 'part_number',
                    'type': 'like',
                    'value': this.filters.part_number
                };
            }

            filtersQuery.push(filter);
        }

        if (typeof this.filters.manufacturer === 'string' && this.filters.manufacturer.length > 0) {
            let filter = {
                'field': 'manufacturer',
                'type': 'like',
                'value': this.filters.manufacturer
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.function === 'string' && this.filters.function.length > 0) {
            let filter = {
                'field': 'function',
                'type': 'like',
                'value': this.filters.function
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.technology === 'string' && this.filters.technology.length > 0) {
            let filter = {
                'field': 'technology',
                'type': 'like',
                'value': this.filters.technology
            };

            filtersQuery.push(filter);
        }

        if (this.filters.sel) {
            if (typeof this.filters.sel.low === 'number' && this.filters.sel.low > this.slidersConfigs.sel.min) {
                let filter = {
                    'field': 'let_min',
                    'type': '>=',
                    'value': this.filters.sel.low
                };

                filtersQuery.push(filter);
            }

            if (typeof this.filters.sel.hi === 'number' && this.filters.sel.hi < this.slidersConfigs.sel.max) {
                let filter = {
                    'field': 'let_max',
                    'type': '<=',
                    'value': this.filters.sel.hi
                };

                filtersQuery.push(filter);
            }
        }

        if (this.filters.tid) {
            if (typeof this.filters.tid.low === 'number' && this.filters.tid.low > this.slidersConfigs.tid.min) {
                let filter = {
                    'field': 'tid_min',
                    'type': '>=',
                    'value': this.filters.tid.low
                };

                filtersQuery.push(filter);
            }

            if (typeof this.filters.tid.hi === 'number' && this.filters.tid.hi < this.slidersConfigs.tid.max) {
                let filter = {
                    'field': 'tid_max',
                    'type': '<=',
                    'value': this.filters.tid.hi
                };

                filtersQuery.push(filter);
            }
        }

        if (typeof this.filters.ddFluence === 'number' && this.filters.ddFluence > this.slidersConfigs.ddFluence.min) {
            let filter = {
                'field': 'dd_fluence',
                'type': '>=',
                'value': Number.parseFloat('1.0e+' + this.filters.ddFluence)
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.criticalVoltage === 'number' && this.filters.criticalVoltage > this.slidersConfigs.criticalVoltage.min) {
            let filter = {
                'field': 'survival_voltage',
                'type': '>=',
                'value': this.filters.criticalVoltage
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.multiPartNumber === 'string' && this.filters.multiPartNumber.length > 0) {
            let values = this.filters.multiPartNumber.split(',');
            let trimmedValues = values.map(s => s.trim()).filter((value, index, arr) => {
                return value.length > 0
            });
            let filter = {
                'field': 'part_number',
                'type': 'like',
                'value': trimmedValues
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.ionParticle === 'object' && this.filters.ionParticle.length > 0) {
            // let ionFiltersToText = '';
            // ionFiltersToText += this.filters.ionParticle.join(' ');

            let filter = {
                'field': 'particle',
                'type': 'like',
                'value': this.filters.ionParticle
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.dataType === 'object' && this.filters.dataType.length > 0) {
            // let dataTypeFiltersToText = '';
            // dataTypeFiltersToText += this.filters.dataType.join(' ');

            let filter = {
                'field': 'data_type',
                'type': 'like',
                'value': this.filters.dataType
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.reference === 'string' && this.filters.reference.length > 0) {
            let filter = {
                'field': 'reference',
                'type': 'like',
                'value': this.filters.reference
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.vendorPartNumber === 'string' && this.filters.vendorPartNumber.length > 0) {
            let filter = {
                'field': 'vendor_part_number',
                'type': 'like',
                'value': this.filters.vendorPartNumber
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.cage === 'string' && this.filters.cage.length > 0) {
            let filter = {
                'field': 'cage',
                'type': 'like',
                'value': this.filters.cage
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.description === 'string' && this.filters.description.length > 0) {
            let filter = {
                'field': 'description',
                'type': 'like',
                'value': this.filters.description
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.tidHdr === 'number' && this.filters.tidHdr > this.slidersConfigs.tidHdr.min) {
            let filter = {
                'field': 'tid_hdr',
                'type': '>=',
                'value': this.filters.tidHdr
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.tidLdr === 'number' && this.filters.tidLdr > this.slidersConfigs.tidLdr.min) {
            let filter = {
                'field': 'tid_ldr',
                'type': '>=',
                'value': this.filters.tidLdr
            };

            filtersQuery.push(filter);
        }

        if (this.filters.selSmd) {
            if (typeof this.filters.selSmd === 'number' && this.filters.selSmd > this.slidersConfigs.selSmd.min) {
                let filter = {
                    'field': 'sel',
                    'type': '>=',
                    'value': this.filters.selSmd
                };

                filtersQuery.push(filter);
            }
        }

        if (typeof this.filters.dsee === 'number' && this.filters.dsee > this.slidersConfigs.dsee.min) {
            let filter = {
                'field': 'dsee',
                'type': '>=',
                'value': this.filters.dsee
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.seu === 'number' && this.filters.seu > this.slidersConfigs.seu.min) {
            let filter = {
                'field': 'seu',
                'type': '>=',
                'value': this.filters.seu
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.neutron === 'number' && this.filters.neutron > this.slidersConfigs.neutron.min) {
            let filter = {
                'field': 'neutron',
                'type': '>=',
                'value': Number.parseFloat('1.0e+' + this.filters.neutron)
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.doseRateLatchup === 'number' && this.filters.doseRateLatchup > this.slidersConfigs.doseRateLatchup.min) {
            let filter = {
                'field': 'dose_rate_latchup',
                'type': '>=',
                'value': Number.parseFloat('1.0e+' + this.filters.doseRateLatchup)
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.doseRateUpset === 'number' && this.filters.doseRateUpset > this.slidersConfigs.doseRateUpset.min) {
            let filter = {
                'field': 'dose_rate_upset',
                'type': '>=',
                'value': Number.parseFloat('1.0e+' + this.filters.doseRateUpset)
            };

            filtersQuery.push(filter);
        }

        if (typeof this.filters.doseRateSurvivability === 'number' && this.filters.doseRateSurvivability > this.slidersConfigs.doseRateSurvivability.min) {
            let filter = {
                'field': 'dose_rate_survivability',
                'type': '>=',
                'value': Number.parseFloat('1.0e+' + this.filters.doseRateSurvivability)
            };

            filtersQuery.push(filter);
        }

        return filtersQuery;
    }

    private clearFilterInputs() {
        if (this.multipleSearchTextarea) {
            this.multipleSearchTextarea.nativeElement.value = '';
        }

        if (this.partNumberFilterElement) {
            this.partNumberFilterElement.nativeElement.value = '';
            let elem = this.partNumberFilterElement.nativeElement.parentNode.parentNode.parentNode.parentNode;
            if (elem) {
                elem.classList.remove('active');
            }
        }
    }

    openSMDProductDialog(row) {
        let rowData = null;
        let thisComponent = this;
        if (row && typeof row === 'object' && typeof row.getCells === 'function') {
            rowData = row.getData();
            let rowCells = row.getCells();

            this.isLoading = true;
            let productObservable;
            if (this._securityRepoService.isAuthenticated) {
                productObservable = this._smdproductsRepo.getProductById(rowData.id);
            } else {
                productObservable = this._smdproductsRepo.getSampleProductById(rowData.id);
            }

            productObservable
                .pipe(catchError(() => {
                    this.isLoading = false;
                    return observableOf(false);
                }))
                .subscribe(data => {
                    this.isLoading = false;
                    let product = null;
                    let isInSampleSet = false;
                    let formattedProduct = {};
                    let results = [];
                    if (data && typeof data === 'object') {
                        if (data['product'] && typeof data['product'] === 'object') {
                            product = data['product'];
                            let hiddenColumns = ['id', 'let_min', 'let_max', 'data_type', 'results'];
                            rowCells.forEach(cell => {
                                let cellDefinition = cell.getColumn().getDefinition();
                                let colName = cellDefinition.title;
                                let cellValue = product[cellDefinition.field];
                                if (typeof cellDefinition.formatter === 'function') {
                                    cellValue = cellDefinition.formatter(cell);
                                }
                                if (hiddenColumns.indexOf(cellDefinition.field) === -1) {
                                    formattedProduct[cellDefinition.field] = { title: colName, value: cellValue };
                                }
                            });
                        }

                        if (data['configs'] && typeof data['configs'] === 'object') {
                            let configs = data['configs'];
                            if (configs['freeSearchPeriod'] && typeof configs['freeSearchPeriod'] === 'object') {
                                this.viewConfigs.freeSearchPeriod.isFirstSearch = configs['freeSearchPeriod']['isFirstSearch'] === true ? true : false;
                                this.viewConfigs.freeSearchPeriod.isFirstDialog = configs['freeSearchPeriod']['isFirstDialog'] === true ? true : false;
                                this.viewConfigs.freeSearchPeriod.isEndDialogBox = configs['freeSearchPeriod']['isEndDialogBox'] === true ? true : false;
                                if (configs['isInSampleSet'] === true) {
                                    isInSampleSet = true;
                                }

                                let remainingSearches = Number(configs['freeSearchPeriod']['remainingSearches']);
                                this.viewConfigs.freeSearchPeriod.remainingSearches = isNaN(remainingSearches) ? 0 : remainingSearches;

                                let remainingDialogs = Number(configs['freeSearchPeriod']['remainingDialogs']);
                                this.viewConfigs.freeSearchPeriod.remainingDialogs = isNaN(remainingDialogs) ? 0 : remainingDialogs;

                                if (this.viewConfigs.freeSearchPeriod.isEndDialogBox && !isInSampleSet) {
                                    let msg = 'You have used up your search allowances. Please subscribe to continue access.';
                                    this._snackBar.open(msg, 'ok', { duration: 12000 })

                                    return;
                                }
                            }
                        }
                    }

                    if (!product) {
                        let config = {
                            data: {
                                messages: ['Failed to fetch product detail!']
                            }
                        };

                        this._matDialog.open(this.errorDialog, config);
                        return;
                    }
                    let dialogData = {
                        isInSampleSet: isInSampleSet,
                        isFirstFreeDialog: thisComponent.viewConfigs.freeSearchPeriod.isFirstDialog,
                        remainingDialogs: thisComponent.viewConfigs.freeSearchPeriod.remainingDialogs,
                        isSaved: thisComponent.isInSavedList(rowData),
                        formattedProduct: formattedProduct,
                        results: results,
                        addToSavedList: function () {
                            return thisComponent.addToSavedList(rowData);
                        }
                    };

                    let config = {
                        data: dialogData,
                        panelClass: ['smdproduct-dialog', 'smddata-popup']
                    };

                    const dialogRef = thisComponent._matDialog.open(SMDProductDialogComponent, config);
                });
        }
    }

    openProductDialog(row, activeTable = '') {
        let rowData = null;
        let thisComponent = this;
        if (row && typeof row === 'object' && typeof row.getCells === 'function') {
            rowData = row.getData();
            let rowCells = row.getCells();

            this.isLoading = true;
            let productObservable;

            if (this._securityRepoService.isAuthenticated) {
                productObservable = this._productsRepo.getProductById(rowData.id);
            } else {
                productObservable = this._productsRepo.getSampleProductById(rowData.id);
            }

            productObservable
                .pipe(catchError(() => {
                    this.isLoading = false;
                    return observableOf(false);
                }))
                .subscribe(data => {
                    this.isLoading = false;
                    let product = null;
                    let isInSampleSet = false;
                    let formattedProduct = {};
                    let results = [];
                    if (data && typeof data === 'object') {
                        if (data['product'] && typeof data['product'] === 'object') {
                            product = data['product'];
                            let hiddenColumns = ['id', 'let_min', 'let_max', 'data_type', 'results'];
                            rowCells.forEach(cell => {
                                let cellDefinition = cell.getColumn().getDefinition();
                                let colName = cellDefinition.title;
                                let cellValue = product[cellDefinition.field];
                                if (typeof cellDefinition.formatter === 'function') {
                                    cellValue = cellDefinition.formatter(cell);
                                }

                                if (hiddenColumns.indexOf(cellDefinition.field) === -1) {
                                    formattedProduct[cellDefinition.field] = { title: colName, value: cellValue };
                                }

                                if (cellDefinition.field === 'results') {
                                    results = cellValue.split('\n');
                                }
                            });
                        }

                        if (data['configs'] && typeof data['configs'] === 'object') {
                            let configs = data['configs'];
                            if (configs['freeSearchPeriod'] && typeof configs['freeSearchPeriod'] === 'object') {
                                this.viewConfigs.freeSearchPeriod.isFirstSearch = configs['freeSearchPeriod']['isFirstSearch'] === true ? true : false;
                                this.viewConfigs.freeSearchPeriod.isFirstDialog = configs['freeSearchPeriod']['isFirstDialog'] === true ? true : false;
                                this.viewConfigs.freeSearchPeriod.isEndDialogBox = configs['freeSearchPeriod']['isEndDialogBox'] === true ? true : false;
                                if (configs['isInSampleSet'] === true) {
                                    isInSampleSet = true;
                                }

                                let remainingSearches = Number(configs['freeSearchPeriod']['remainingSearches']);
                                this.viewConfigs.freeSearchPeriod.remainingSearches = isNaN(remainingSearches) ? 0 : remainingSearches;

                                let remainingDialogs = Number(configs['freeSearchPeriod']['remainingDialogs']);
                                this.viewConfigs.freeSearchPeriod.remainingDialogs = isNaN(remainingDialogs) ? 0 : remainingDialogs;

                                if (this.viewConfigs.freeSearchPeriod.isEndDialogBox && !isInSampleSet) {
                                    let msg = 'You have used up your search allowances. Please subscribe to continue access.';
                                    this._snackBar.open(msg, 'ok', { duration: 12000 })

                                    return;
                                }
                            }
                        }
                    }

                    if (!product) {
                        let config = {
                            data: {
                                messages: ['Failed to fetch product detail!']
                            }
                        };

                        this._matDialog.open(this.errorDialog, config);
                        return;
                    }

                    let dialogData = {
                        isInSampleSet: isInSampleSet,
                        isFirstFreeDialog: thisComponent.viewConfigs.freeSearchPeriod.isFirstDialog,
                        remainingDialogs: thisComponent.viewConfigs.freeSearchPeriod.remainingDialogs,
                        isSaved: thisComponent.isInSavedList(rowData),
                        formattedProduct: formattedProduct,
                        results: results,
                        addToSavedList: function () {
                            return thisComponent.addToSavedList(rowData);
                        }
                    };

                    let config = {
                        data: dialogData,
                        panelClass: ['product-dialog', 'data-popup']
                    };

                    const dialogRef = thisComponent._matDialog.open(ProductDialogComponent, config);
                });
        }
    }

    getAutoComplete(event, type) {
        let queryString = event.target.value;

        if (queryString.charAt(0) == '5' && queryString.length <= 2 && type == 'part_number') {
            this.autoCompleteArray = ['5962-'];
        } else {
            this._smdproductsRepo.getAutocomplete({ 'column': type, 'data': queryString }).subscribe(data => {
                this.autoCompleteArray = data;
            });
        }
    }

    clearAutoComplete() {
        this.autoCompleteArray = [];
    }

    addToSavedList(product): boolean {
        if (product && typeof product === 'object') {
            if (typeof product.id !== 'number') {
                return false;
            }

            let isInList = this._savedProducts.find((elem) => {
                return elem.id === product.id
            }) ? true : false;
            if (!isInList) {
                this._savedProducts.push(product);
                // this.savedProductsTable.setData(this._savedProducts);
            }

            if (this.isTab(4)) {
                let isInList = this._savedSMDProducts.find((elem) => {
                    return elem.id === product.id
                }) ? true : false;
                if (!isInList) {
                    this._savedSMDProducts.push(product);
                    this.savedSMDProductsTable.setData(this._savedSMDProducts);
                }
            } else {
                let isInList = this._savedMainProducts.find((elem) => {
                    return elem.id === product.id
                }) ? true : false;
                if (!isInList) {
                    this._savedMainProducts.push(product);
                    this.savedProductsTable.setData(this._savedMainProducts);
                }

            }

            this._snackBar.open('Entry was saved!', null, { duration: 3000 })
            return true;
        }

        return false;
    }

    isInSavedList(product): boolean {
        if (!product || typeof product !== 'object') {
            return false;
        }

        return this._savedProducts.find((elem) => {
            return elem.id === product.id
        }) ? true : false;
    }

    clearSavedList() {
        this._savedProducts = [];
        this._savedSMDProducts = [];
        this._savedMainProducts = [];
        this.savedProductsTable.setData(this._savedMainProducts);
        this.savedSMDProductsTable.setData(this._savedSMDProducts);
    }

    setSavedListFilterByPartNumber(partNumber) {
        if (!partNumber || typeof partNumber !== 'string') {
            return;
        }

        let filter = {
            'field': 'part_number',
            'type': '=',
            'value': partNumber
        };

        let filters = [filter];
        this.savedProductsTable.setFilter(filters);
    }

    onClearMultipleSearch() {
        if (this.multipleSearchTextarea && this.multipleSearchTextarea.nativeElement) {
            this.multipleSearchTextarea.nativeElement.value = '';
        }

        if (this.filters.multiPartNumber !== '') {
            this.filters.multiPartNumber = '';
            if (this.isTab(4)) {
                this.smdProductsTable.setFilter([]);
            } else {
                this.tabulatorTable.setFilter([]);
            }
        }
    }

    onSubmitMultipleSearch() {
        let val = '';
        if (this.multipleSearchTextarea && this.multipleSearchTextarea.nativeElement) {
            val = this.multipleSearchTextarea.nativeElement.value;
        }

        if (typeof val === 'string') {
            val = val.trim();
        } else {
            val = '';
        }

        if (val !== this.filters.multiPartNumber) {
            this.filters.multiPartNumber = val;
            if (this.isTab(4)) {
                this.smdProductsTable.setFilter([]);
            } else {
                this.tabulatorTable.setFilter([]);
            }
        }
    }

    onScroll(event) {
        if (event.target.scrollTop > 2) {
            this.isFiltersInputContainerScrolled = true;
        } else {
            this.isFiltersInputContainerScrolled = false;
        }
    }

    onSavedListIconClick(event) {
        this._isMultipleSearchIconActive = false;
        this._isSavedListIconActive = !(this._isSavedListIconActive);

        this._changeDetectorRef.detectChanges();
        this.productsSidenavContainer.updateContentMargins();
    }

    onMultipleSearchIconClick(event) {
        this._isSavedListIconActive = false;
        this._isMultipleSearchIconActive = !(this._isMultipleSearchIconActive);

        this._changeDetectorRef.detectChanges();
        this.productsSidenavContainer.updateContentMargins();
    }

    onChangeProductsFileInputElement(event) {
        this.isProductsFileSelected = this.productsFileInputElementRef?.nativeElement?.files?.length > 0;
    }

    onChangeSmdProductsFileInputElement(event) {
        this.isSmdProductsFileSelected = this.smdProductsFileInputElementRef?.nativeElement?.files?.length > 0;
    }

    onUploadProductsCsv(event, type = '', action = '') {
        if (this.isProductsFileSelected || this.isSmdProductsFileSelected) {
            this.isLoading = true;
            const productRepo = type == 'smd' ? this._smdproductsRepo : this._productsRepo;
            const ref = type == 'smd' ? this.smdProductsFileInputElementRef : this.productsFileInputElementRef;
            productRepo.uploadProductsCsv(ref.nativeElement.files[0], action)
                .pipe(catchError((err) => {
                    this.isLoading = false;
                    return observableOf({ error: err });
                }))
                .subscribe(data => {
                    this.isLoading = false;
                    if (data && typeof data === 'object') {
                        if (data['error']) {
                            let messages = ['Failed to upload products!'];
                            if (data['error']['error'] && data['error']['error']['detail']) {
                                messages.push('Error: ' + data['error']['error']['detail']);
                            }

                            let config = {
                                data: {
                                    messages: messages
                                }
                            };

                            this._matDialog.open(this.errorDialog, config);

                            return;
                        }

                        if (data['uploadedProductsCount'] || data['uploadedProductsCount'] == 0) {
                            let msg = data['uploadedProductsCount'] + ' products were uploaded.';
                            this._snackBar.open(msg, 'ok', { duration: 20000 });

                            return;
                        }
                    }

                    let config = {
                        data: {
                            messages: ['An unknown error occurred!']
                        }
                    };

                    this._matDialog.open(this.errorDialog, config);
                });
        }
    }

    onSelectedTabIndexChange(event) {
        this.clearFilters(event);
        this._router.navigate(['/products'], { queryParams: { tab: event } });
    }

    public isTablet() {
        return window.innerWidth < 1433 ? true : false
    }

    public isTab(number: number): boolean {
        return (this.selectedTabIndex == number);
    }

    public onSearchClicked() {
        if (this.isTab(3) && this.uploadFileComponent.tableReady) {
            this.uploadFileComponent.onSearch();
        }
    }

    public onRefClicked(value: string) {
        this.partNumberFilterElement.nativeElement.value = value;
        this.selectedTabIndex = 0;
        this.type = '=';
        this.applyFilter(event, this.type);
        this.type = '';
    }

    public getIsTableAutopop(): boolean {
        if (this.uploadFileComponent !== undefined && this.uploadFileComponent.isTableAutopop) {
            return this.uploadFileComponent.isTableAutopop;
        }
        return false;
    }

    public isStep(number: number): boolean {
        if (this.uploadFileComponent !== undefined) {
            return (this.uploadFileComponent.isStep == number);
        }
        return false;
    }

    public onChooseColumn() {
        if (this.uploadFileComponent !== undefined) {
            this.uploadFileComponent.onSelectColumn();
        }
    }

    public onSearch() {
        if (this.uploadFileComponent !== undefined) {
            this.uploadFileComponent.onSearch();
        }
    }

    public onRestart() {
        if (this.uploadFileComponent !== undefined) {
            this.uploadFileComponent.restart();
            this.clearFilters(event);
        }
    }
}
